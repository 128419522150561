import React from "react"
import { Link } from "gatsby"
import logo from "./logo.png"

const Logo = (props) => (
  <div className="site-logo">
    <Link to="/"><img className = "logo-image" src={logo} alt="Logo" /></Link>
    
  </div>
)

export default Logo