import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p>Message Harry directly on  <a href="https://www.facebook.com/digitalavsolutions" target="_blank" rel="noopener noreferrer">
        FaceBook
      </a> or  
      <Link  to="/contact"> Contact Page</Link>
      </p>
    </div>
  </footer>
)

export default Footer