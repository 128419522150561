import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
// Components
import { Link, graphql } from "gatsby"
import { RiArrowDownLine, RiArrowRightSLine } from "react-icons/ri"
import PostCard from "../components/post-card"

const PostMaker = ({ data }) => (
  <section className="home-posts">
    <h2>Latest in our <strong>  Services</strong> <span class="icon -right"><RiArrowDownLine/></span></h2>
    <div className="grids col-1 sm-2 lg-3">
      {data}
    </div>
    <Link className="button" to="/contact">Free Quote<span class="icon -right"><RiArrowRightSLine/></span></Link>
  </section>
)

          
const Tags = ({ pageContext, data }) => {
  const posts = data.allMarkdownRemark.edges
            .filter(edge => !!edge.node.frontmatter)
            .filter(edge => !!edge.node.frontmatter.tags)
            .map(edge =>
              <PostCard key={edge.node.id} data={edge.node} />
          )
  const { tag } = pageContext
  const { edges } = data.allMarkdownRemark
  const tagHeader = `${tag} Solutions`
  return (
    <Layout className="page">
    <div>
      <h1>{tagHeader}</h1>
      <PostMaker data={posts} />
      
        {edges.map(() => {
          
          return (
              <Link to="/"></Link>  
          )
        })}
      
      
            
      <Link to="/tags">All services</Link>
    </div>
    </Layout>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}
export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { tags: { eq: $tag } } } 
            limit: 10
    ) {
      totalCount
      edges {
        node {
          id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  slug
                  tags
                  title
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }
          fields {
            slug
          }
          
        }
      }
    }
  }
`